.body {
  position: absolute;
  width: 100%;
  background-color: #ffffff;
  z-index: -10;
}

.landing-page::before {
  background-color: #ffffff;
}

.landing-main {
  width: 100%;
  min-height: calc(100vh - 97px - 150px);
}

.landing-banner {
  width: 100%;
  margin-top: -60px;
  position: relative;
}

.landing-banner::before {
  content: "";
  position: absolute;
  background-image: url("/src/assets/landing-Page.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center top;
  opacity: 0.8;
  width: 100%;
  height: 100%;
  z-index: -1;
}

.landing-gradient {
  position: relative;
  z-index: 1;
  width: 100%;
  height: 307px;
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0) 56.25%,
    rgba(255, 255, 255, 0.95) 90%
  );
  transform: rotate(-180deg);
}

.landing-info {
  width: 60%;
  max-width: 650px;
  min-width: 594.75px;
  margin: -80px auto 0;
  text-align: center;
  font-style: normal;
}

.landing-info h1 {
  margin: 96px 0 40px 0;
  font-weight: 800;
  font-size: 80px;
  line-height: 109px;
  color: #3e4954;
}

.landing-info h2 {
  font-weight: 600;
  font-size: 28px;
  line-height: 38px;
  margin-bottom: 80px;
  color: #3e4954;
}

.landing-info h3 {
  font-weight: 600;
  font-size: 30px;
  line-height: 41px;
  color: #2e495c;
}

.landing-info span {
  font-weight: bold;
  font-size: 50px;
  line-height: 68px;
}

.landing-info button {
  width: 594.75px;
  height: 78px;
  background: #1b98e3;
  border-radius: 55.7143px;
  border: none;
  font-style: normal;
  font-weight: 600;
  font-size: 30.6429px;
  line-height: 42px;
  text-transform: capitalize;
  color: #ffffff;
  position: relative;
  z-index: 1;
  cursor: pointer;
  font-family: "Open Sans";
}

.landing-info button:hover {
  background: #2188c6;
}

.landing-info button:active {
  background: #1b98e3;
}

.info-section {
  width: 90%;
  margin: -115px auto 110px auto;
  background: linear-gradient(
    95.24deg,
    rgba(255, 255, 255, 0.9) 7.8%,
    rgba(255, 255, 255, 0.2) 100.58%
  );
  border: 1px solid rgba(255, 255, 255, 0.3);
  backdrop-filter: blur(10px);
  border-radius: 40px;
  padding: 10px 10px 40px 10px;
}

.info-container {
  width: 80%;
  margin: 60px auto;
}

.info-text {
  font-style: normal;
  font-weight: 600;
  font-size: 34px;
  line-height: 46px;
  text-align: center;
  color: #1b98e3;
  position: relative;
  z-index: 2;
}

.info-description {
  font-style: normal;
  font-weight: normal;
  text-align: center;
  font-size: 22px;
  line-height: 30px;
  color: #c5c5c5;
}

.info-row {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 40px;
  margin-top: 57px;
}

.info-col {
  flex: 0 0 25%;
  width: 25%;
  margin: 0 0 0 auto;
}

.info-col h2 {
  font-style: normal;
  font-weight: 800;
  font-size: 22px;
  line-height: 30px;
  color: #1b98e3;
}

.info-col-des {
  width: 200px;
  max-width: 85%;
  font-style: normal;
  font-weight: normal;
  font-size: 22px;
  line-height: 30px;
  color: #1b98e3;
}

.landing-bottom-gradient {
  width: 100%;
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 1) 100%
  );
  height: 195px;
  z-index: 1;
}

.pb-30 {
  padding-bottom: 30px;
}

.landing-footer-section {
  background-color: #ffffff;
}

@media (max-width: 945px) {
  .landing-info {
    margin: -120px auto 0;
  }
  .landing-info h1 {
    margin: 40px 0 40px 0;
    font-size: 60px;
    line-height: 90px;
  }
  .info-col {
    margin: 0;
    width: 33%;
  }
}

@media (max-width: 768px) {
  .info-row {
    margin-top: 0px;
  }
  .landing-banner::before {
    opacity: 0.6;
    margin-top: 65px;
  }
  .landing-info {
    width: 90%;
    min-width: 0;
    margin-top: 0;
    margin-bottom: -100px;
  }
  .landing-info h1 {
    font-style: normal;
    font-weight: 800;
    font-size: 30px;
    line-height: 40.85px;
    margin-top: -100px;
  }
  .landing-info h2 {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 21.79px;
    margin-top: -20px;
    margin-bottom: 30px;
  }
  .landing-info h3 {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 21.79px;
  }
  .landing-info span {
    font-weight: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 27.24px;
  }
  .landing-info button {
    width: 275px;
    height: 39.88px;
    border-radius: 55.7143px;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
  }

  .landing-bottom-gradient {
    height: 85px;
  }
  .info-section {
    width: 100%;
    margin: 0 auto;
    background-color: #ffffff;
    border: none;
    border-radius: 0;
    padding: 80px 20px 30px;
    backdrop-filter: none;
  }
  .info-container {
    width: 90%;
    margin: 20px auto 20px;
  }
  .info-text {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 21.79px;
    text-align: left;
  }
  .info-col {
    flex: 0 0 100%;
    width: 100%;
    margin-top: 15px;
  }
  .info-col h2 {
    font-style: normal;
    font-weight: 800;
    font-size: 22px;
    line-height: 30px;
  }
  .info-col-des {
    width: 80%;
    margin: 0 10% 0 10%;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 22px;
    margin-top: -48px;
    margin-left: 40px;
  }
  .info-description {
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    margin-top: -20px;
    margin-bottom: 50px;
    text-align: left;
    padding-left: 23px;
  }
  .pb-30 {
    padding-bottom: 0;
  }
  .landing-footer-section {
    background-color: #ffffff;
  }
}

@media (max-width: 350px) {
  .landing-info button {
    width: 200px;
    height: 39.88px;
  }
}

@media (min-width: 1800px) {
  .info-section {
    margin: 0px auto 80px auto;
  }
}
